import { Button, Col, H1, H2, Hidden, Row } from '@ahmdigital/ui';
import { get, isEmpty, isNil } from 'lodash/fp';
import React from 'react';

import { Cta, DesktopVideoWrapper, StyledCol, StyledSection } from './styles';
import { SANITY_MEDIA_FILES } from '../../../../ahm-constants/ui';
import { UrlObject } from '../../../../types/__codegen__/sanity/graphql';
import type { HeroSectionProps } from '../../types';

const getCtaComponent = (urlObject: UrlObject) => {
  const { url, name } = urlObject;
  return (
    <Button
      icon={{
        name: 'arrow-right-long',
        position: 'right',
        size: 'large',
      }}
      to={url}
      variant={['flair', 'wide', 'wideSpaceBetween']}
    >
      {name}
    </Button>
  );
};

const HeroSection = ({ heroOfferSection, name, subtitle, urlObject }: HeroSectionProps) => {
  const activeOffers = get('props.activeOffers', heroOfferSection);

  return (
    <Row isBoxed>
      <StyledCol df>
        <StyledSection>
          <H1>{name}</H1>
          {!isEmpty(subtitle) && (
            <Hidden xs sm md>
              <H2 looksLike="h6" variant={['regular', 'paddingBottomSpacer10']}>
                {subtitle}
              </H2>
            </Hidden>
          )}
          {heroOfferSection}
          {urlObject && (
            <Cta $extraTopMargin={isNil(activeOffers)}>
              <Col sm={12} md={6}>
                {getCtaComponent(urlObject)}
              </Col>
            </Cta>
          )}
        </StyledSection>
        <DesktopVideoWrapper $extraTopMargin={!isNil(activeOffers)}>
          <video autoPlay muted playsInline height="400px" width="340px">
            <source src={SANITY_MEDIA_FILES.GLITCH_VIDEO} type="video/mp4" />
          </video>
        </DesktopVideoWrapper>
      </StyledCol>
    </Row>
  );
};

export default HeroSection;
