import React from 'react';
import styled from 'styled-components';

type ImageWithPosition = {
  image: string;
  position?: string;
};

type BackgroundImageProps = {
  className?: string;
  children: React.ReactNode;
  desktop: ImageWithPosition;
  mobile: ImageWithPosition;
  tablet: ImageWithPosition;
};

const BackgroundImage = ({ className, children }: BackgroundImageProps) => <div className={className}>{children}</div>;

const StyledBackgroundImage = styled(BackgroundImage)<BackgroundImageProps>`
  background-origin: content-box;
  background-repeat: no-repeat;

  ${({ desktop, tablet, theme }) => `
    @media (min-width: ${theme.media.sm}) {
      background-image: url('${tablet.image}');
      background-position: right 5% bottom 30%;
      background-size: 17rem;
    }

    @media (min-width: ${theme.media.md}) {
      background-image: url('${desktop.image}');
      background-position: right 10% bottom 40%;
      background-size: 22rem;
    }
  `}
`;

export default StyledBackgroundImage;
